import React, { useMemo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  TableFooter,
  Button,
  useMediaQuery,
} from "@mui/material";
import useComPath from "../components/events/hooks/useComPath";
import RegHeader from "../components/headers/regHeader";
import Footer from "../components/footer/footer";
import RefreshIcon from "@mui/icons-material/Refresh";
import CustomCircularP from "../widgets/CustomCircularP";
import { extractDate, extractTime } from "../utility/utility";
import * as XLSX from "xlsx";
function createData(
  day,
  vip,
  media,
  students,
  delegation,
  ec,
  preEventReg,
  eventDaysReg,
  totalVisitors,
  repeatVisitors,
  total
) {
  return {
    day,
    vip,
    media,
    students,
    delegation,
    ec,
    preEventReg,
    eventDaysReg,
    totalVisitors,
    repeatVisitors,
    total,
  };
}

const cutoffDate = new Date("2024-05-25");

export default function Pmtxfootfall() {
  const [refresh, setRefresh] = useState(false);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { data, isLoading, isError, error } = useComPath(
    `https://apis.expoplanner.in/user/scanned-users-footfall?pb=pmtx2024-imtma&refresh=${refresh}`
  );

  const rows = useMemo(() => {
    if (!data) return [];

    const rolesMapping = {
      VIP: "vip",
      MEDIA: "media",
      STUDENT: "students",
      student: "students",
      BULK_STUDENT: "students",
      VISITOR: "visitor",
      visitor: "visitor",
      DELEGATION: "delegation",
      EC: "ec",
    };

    const initialCounts = {
      day1: {
        vip: 0,
        media: 0,
        students: 0,
        delegation: 0,
        ec: 0,
        preEventReg: 0,
        eventDaysReg: 0,
        totalVisitors: 0,
        repeatVisitors: 0,
        total: 0,
      },
      day2: {
        vip: 0,
        media: 0,
        students: 0,
        delegation: 0,
        ec: 0,
        preEventReg: 0,
        eventDaysReg: 0,
        totalVisitors: 0,
        repeatVisitors: 0,
        total: 0,
      },
      day3: {
        vip: 0,
        media: 0,
        students: 0,
        delegation: 0,
        ec: 0,
        preEventReg: 0,
        eventDaysReg: 0,
        totalVisitors: 0,
        repeatVisitors: 0,
        total: 0,
      },
      day4: {
        vip: 0,
        media: 0,
        students: 0,
        delegation: 0,
        ec: 0,
        preEventReg: 0,
        eventDaysReg: 0,
        totalVisitors: 0,
        repeatVisitors: 0,
        total: 0,
      },
    };

    const visitorCheck = {
      vip: {},
      media: {},
      students: {},
      delegation: {},
      ec: {},
      visitor: {},
      preEventReg: {},
      eventDaysReg: {},
    };

    data.forEach((user) => {
      const role = rolesMapping[user.role];
      if (!role) return;
      const registrationDate = new Date(user.createdOn);
      const isPreEventReg = registrationDate < cutoffDate;

      ["day1", "day2", "day3", "day4"].forEach((day) => {
        if (user[day]) {
          if (!visitorCheck[role][user.barcode_id]) {
            initialCounts[day][role] += 1;
            visitorCheck[role][user.barcode_id] = [day];
          } else {
            if (!visitorCheck[role][user.barcode_id].includes(day)) {
              visitorCheck[role][user.barcode_id].push(day);
              initialCounts[day].repeatVisitors += 1;
            } else {
              initialCounts[day][role] += 0;
            }
          }

          if (role === "visitor") {
            if (isPreEventReg) {
              //initialCounts[day].preEventReg += 1;
              let role = "preEventReg";
              if (!visitorCheck[role][user.barcode_id]) {
                initialCounts[day][role] += 1;
                visitorCheck[role][user.barcode_id] = [day];
              } else {
                if (!visitorCheck[role][user.barcode_id].includes(day)) {
                  visitorCheck[role][user.barcode_id].push(day);
                } else {
                  initialCounts[day][role] += 0;
                }
              }
            } else {
              //initialCounts[day].eventDaysReg += 1;
              let role = "eventDaysReg";
              if (!visitorCheck[role][user.barcode_id]) {
                initialCounts[day][role] += 1;
                visitorCheck[role][user.barcode_id] = [day];
              } else {
                if (!visitorCheck[role][user.barcode_id].includes(day)) {
                  visitorCheck[role][user.barcode_id].push(day);
                } else {
                  initialCounts[day][role] += 0;
                }
              }
            }
          }
        }
      });
    });

    ["day1", "day2", "day3", "day4"].forEach((day) => {
      initialCounts[day].totalVisitors =
        initialCounts[day].vip +
        initialCounts[day].media +
        initialCounts[day].students +
        initialCounts[day].delegation +
        initialCounts[day].ec +
        initialCounts[day].preEventReg +
        initialCounts[day].eventDaysReg;

      initialCounts[day].total =
        initialCounts[day].totalVisitors + initialCounts[day].repeatVisitors;
    });

    return [
      { day: "Day 1", ...initialCounts.day1 },
      { day: "Day 2", ...initialCounts.day2 },
      { day: "Day 3", ...initialCounts.day3 },
      { day: "Day 4", ...initialCounts.day4 },
    ];
  }, [data]);

  const columnSums = rows.reduce(
    (totals, row) => ({
      vip: totals.vip + row.vip,
      media: totals.media + row.media,
      students: totals.students + row.students,
      delegation: totals.delegation + row.delegation,
      ec: totals.ec + row.ec,
      preEventReg: totals.preEventReg + row.preEventReg,
      eventDaysReg: totals.eventDaysReg + row.eventDaysReg,
      totalVisitors: totals.totalVisitors + row.totalVisitors,
      repeatVisitors: totals.repeatVisitors + row.repeatVisitors,
      total: totals.total + row.total,
    }),
    {
      vip: 0,
      media: 0,
      students: 0,
      delegation: 0,
      ec: 0,
      preEventReg: 0,
      eventDaysReg: 0,
      totalVisitors: 0,
      repeatVisitors: 0,
      total: 0,
    }
  );

  function transformFootfallData(items) {
    const transformedList = [];

    items.forEach((user) => {
      const {
        day1,
        day2,
        day3,
        day4,
        day5,
        day6,
        day7,
        day1timestamp,
        day2timestamp,
        day3timestamp,
        day4timestamp,
        day5timestamp,
        day6timestamp,
        day7timestamp,
        role,
        ...rest
      } = user;

      let entryCount = 0;

      const addEntry = (timestamp) => {
        const date = new Date(timestamp);
        const currentRole = entryCount > 0 ? `${role} (repeat)` : role;
        transformedList.push({
          ...rest,
          role: currentRole,
          Day: date.toISOString().split("T")[0],
          Time: date.toISOString().split("T")[1].split(".")[0], // to get time without milliseconds
        });
        entryCount++;
      };

      if (day1 && day1timestamp) addEntry(day1timestamp);
      if (day2 && day2timestamp) addEntry(day2timestamp);
      if (day3 && day3timestamp) addEntry(day3timestamp);
      if (day4 && day4timestamp) addEntry(day4timestamp);
    });

    return transformedList;
  }

  const exportAuditReport = () => {
    const result = transformFootfallData(data);
    const filteredData = result.map((item) => {
      const {
        _id,
        user_id,
        barcode_id,
        eventsList,
        title,
        firstName,
        lastName,
        phone,
        email,
        companyName,
        instituteName,
        name,
        role: user_type,
        address,
        selectedCountry,
        selectedState,
        selectedCity,
        pin,
        website,
        telephone,
        designation,
        department,
        selectedEventSource,
        selectedIndustrySector,
        selectedCategories,
        profile_image,
        qrLink,
        createdOn,
        Day,
        Time,
      } = item;
      const facteq2024Categories =
        selectedCategories?.["FACTEQ 2024"]?.join(",") ||
        "No categories selected";
      const pmtx2024Categories =
        selectedCategories?.["PMTX 2024"]?.join(",") ||
        "No categories selected";
      const productCategories = `FACTEQ 2024: ${facteq2024Categories} and PMTX 2024: ${pmtx2024Categories}`;

      return {
        "Object Id": _id,
        "Profile Image": profile_image,
        "User ID": user_id,
        "Barcode ID": barcode_id,
        "User Type": user_type,
        "Events List": Array.isArray(eventsList) ? eventsList?.join(", ") : [],
        Title: title,
        "First Name": firstName,
        "Last Name": lastName,
        name: name,
        "Phone Number": phone,
        Email: email,
        "Company/Institute Name": `${companyName ? companyName : ""}${
          instituteName ? instituteName : ""
        }`,
        Address: address,
        Country: selectedCountry?.name,
        State: selectedState?.name,
        City: selectedCity,
        "Postal Code": pin,
        Website: website,
        Telephone: telephone,
        Designation: designation,
        Department: department,
        "Event Source": selectedEventSource,
        "Industry Sector": selectedIndustrySector,
        "Product Categories": productCategories,
        "QR Link": qrLink,
        "Registration Date": extractDate(createdOn),
        "Registration Time": extractTime(createdOn),
        Day: Day,
        Time: Time,
      };
    });

    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "PMTX footfall report");
    XLSX.writeFile(wb, "pmtx_report.xlsx");
  };

  return (
    <Box height="100vh" display={"flex"} flexDirection={"column"}>
      <RegHeader />
      <CustomCircularP show={isLoading} />
      <Box p={2} flex={1}>
        <Box
          display={"flex"}
          flexDirection={isSmallScreen ? "column" : "row"}
          alignItems="center"
        >
          <Typography
            variant="h5"
            fontWeight={600}
            style={{ flex: 1, textAlign: isSmallScreen ? "center" : "left" }}
          >
            PMTX 2024 Footfall
          </Typography>
          <Button
            sx={{
              alignSelf: isSmallScreen ? "center" : "flex-end",
              mt: isSmallScreen ? 2 : 0,
            }}
            onClick={() => {
              //setRefresh((prev) => !prev);
              exportAuditReport();
            }}
            size="small"
            variant="outlined"
            startIcon={<RefreshIcon />}
          >
            Refresh
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Day</TableCell>
                <TableCell align="right">VIP</TableCell>
                <TableCell align="right">Media</TableCell>
                <TableCell align="right">Students</TableCell>
                <TableCell align="right">Delegation</TableCell>
                <TableCell align="right">EC</TableCell>
                <TableCell align="right">Pre-Event Registration</TableCell>
                <TableCell align="right">Event Days Registration</TableCell>
                <TableCell align="right">
                  Total Visitors(excl. repeat)
                </TableCell>
                <TableCell align="right">Repeats</TableCell>
                <TableCell align="right">Total(incl. repeat)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row) => (
                <TableRow key={row.day}>
                  <TableCell component="th" scope="row">
                    {row.day}
                  </TableCell>
                  <TableCell align="right">{row.vip}</TableCell>
                  <TableCell align="right">{row.media}</TableCell>
                  <TableCell align="right">{row.students}</TableCell>
                  <TableCell align="right">{row.delegation}</TableCell>
                  <TableCell align="right">{row.ec}</TableCell>
                  <TableCell align="right">{row.preEventReg}</TableCell>
                  <TableCell align="right">{row.eventDaysReg}</TableCell>
                  <TableCell align="right">{row.totalVisitors}</TableCell>
                  <TableCell align="right">{row.repeatVisitors}</TableCell>
                  <TableCell align="right">{row.total}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell sx={{ fontSize: "16px" }} component="th" scope="row">
                  Totals
                </TableCell>
                <TableCell sx={{ fontSize: "16px" }} align="right">
                  {columnSums.vip}
                </TableCell>
                <TableCell sx={{ fontSize: "16px" }} align="right">
                  {columnSums.media}
                </TableCell>
                <TableCell sx={{ fontSize: "16px" }} align="right">
                  {columnSums.students}
                </TableCell>
                <TableCell sx={{ fontSize: "16px" }} align="right">
                  {columnSums.delegation}
                </TableCell>
                <TableCell sx={{ fontSize: "16px" }} align="right">
                  {columnSums.ec}
                </TableCell>
                <TableCell sx={{ fontSize: "16px" }} align="right">
                  {columnSums.preEventReg}
                </TableCell>
                <TableCell sx={{ fontSize: "16px" }} align="right">
                  {columnSums.eventDaysReg}
                </TableCell>
                <TableCell sx={{ fontSize: "16px" }} align="right">
                  {columnSums.totalVisitors}
                </TableCell>
                <TableCell sx={{ fontSize: "16px" }} align="right">
                  {columnSums.repeatVisitors}
                </TableCell>
                <TableCell sx={{ fontSize: "16px" }} align="right">
                  {columnSums.total}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
      <Footer />
    </Box>
  );
}
