import React, { useMemo, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
  Stack,
  InputAdornment,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import useCommonGetInit from "../../../events/hooks/useCommonGetInit";

const ExhibitorFilterBar = ({ onSearch, onFilter }) => {
  const { data: Halls, isLoading: hallsLoading } = useCommonGetInit(
    `user/get-halls-list-1`
  );
  const { data: countryList, isLoading: countryLoading } = useCommonGetInit(
    `user/get-exhibitor-country-list`
  );

  const { data: Mdata, isLoading: loading } = useCommonGetInit(
    `user/get-product-categories-1`
  );

  const uniqueCategories = useMemo(() => {
    if (!Mdata) return []; // Return an empty array if Mdata is null or undefined

    return Mdata.map((item) => item.Category) // Extract the Category object from each item
      .filter(
        (category, index, self) =>
          index ===
          self.findIndex(
            (c) => c.code === category.code && c.name === category.name
          )
      )
      .sort((a, b) => a.name.localeCompare(b.name)); // Sort alphabetically by the name field
  }, [Mdata]);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedHall, setSelectedHall] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCat, setSelectedCat] = useState("");

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (onSearch) {
      onSearch(value);
    }
  };

  const handleHallChange = (event) => {
    const value = event.target.value;
    setSelectedHall(value);
    if (onFilter) {
      onFilter({ hall: value, country: selectedCountry, cat: selectedCat });
    }
  };

  const handleCountryChange = (event) => {
    const value = event.target.value;
    setSelectedCountry(value);
    if (onFilter) {
      onFilter({ hall: selectedHall, country: value, cat: selectedCat });
    }
  };
  const handleCatChange = (event) => {
    const value = event.target.value;
    setSelectedCat(value);
    if (onFilter) {
      onFilter({ hall: selectedHall, country: selectedCountry, cat: value });
    }
  };

  const clearHallFilter = () => {
    setSelectedHall("");
    if (onFilter) {
      onFilter({ hall: "", country: selectedCountry, cat: selectedCat });
    }
  };

  const clearCountryFilter = () => {
    setSelectedCountry("");
    if (onFilter) {
      onFilter({ hall: selectedHall, country: "", cat: selectedCat });
    }
  };
  const clearCatFilter = () => {
    setSelectedCat("");
    if (onFilter) {
      onFilter({ hall: selectedHall, country: selectedCountry, cat: "" });
    }
  };

  return (
    <Box sx={{ mb: 1 }}>
      <Typography variant="h4" gutterBottom>
        Exhibitors List
      </Typography>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
        {/* Search Field */}
        <TextField
          fullWidth
          placeholder="Search Exhibitors"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            ),
          }}
        />

        {/* Filter by Hall */}
        <FormControl size="small" variant="outlined" sx={{ minWidth: 200 }}>
          <InputLabel>Hall</InputLabel>
          <Select
            label="Hall"
            value={selectedHall}
            onChange={handleHallChange}
            endAdornment={
              selectedHall ? (
                <IconButton
                  size="small"
                  onClick={clearHallFilter}
                  aria-label="Clear Hall Filter"
                >
                  <ClearIcon />
                </IconButton>
              ) : null
            }
          >
            {Halls?.map((hall) => (
              <MenuItem key={hall} value={hall.name}>
                {hall.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Filter by Country */}
        <FormControl size="small" variant="outlined" sx={{ minWidth: 200 }}>
          <InputLabel>Country</InputLabel>
          <Select
            label="Country"
            value={selectedCountry}
            onChange={handleCountryChange}
            endAdornment={
              selectedCountry ? (
                <IconButton
                  size="small"
                  onClick={clearCountryFilter}
                  aria-label="Clear Country Filter"
                >
                  <ClearIcon />
                </IconButton>
              ) : null
            }
          >
            {countryList?.map((country) => (
              <MenuItem key={country} value={country.name}>
                {country.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" variant="outlined" sx={{ minWidth: 200 }}>
          <InputLabel>Product Category</InputLabel>
          <Select
            label="Product Category"
            value={selectedCat}
            onChange={handleCatChange}
            endAdornment={
              selectedCat ? (
                <IconButton
                  size="small"
                  onClick={clearCatFilter}
                  aria-label="Clear Country Filter"
                >
                  <ClearIcon />
                </IconButton>
              ) : null
            }
          >
            {uniqueCategories?.map((item) => (
              <MenuItem key={item.code} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </Box>
  );
};

export default ExhibitorFilterBar;
