import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Grid,
  Box,
  CardContent,
  Card,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import NaturePeopleIcon from "@mui/icons-material/NaturePeople";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { ThemeProvider } from "@mui/material/styles";
import bgBanner from "../../../assets/gifs/ExpoBG.gif";
import { ExpoTheme } from "../../../theme/theme";
import { CircularProgressbar } from "react-circular-progressbar";
import SideMenuBar from "../../../widgets2024/SideMenuBar";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  LabelList,
} from "recharts";

// Visitor registration by month
const dataByMonth = [
  { month: "Jan", visitors: 100 },
  { month: "Feb", visitors: 120 },
  { month: "Mar", visitors: 150 },
  { month: "Apr", visitors: 130 },
  { month: "May", visitors: 170 },
  { month: "Jun", visitors: 160 },
  { month: "Jul", visitors: 140 },
  { month: "Aug", visitors: 180 },
  { month: "Sep", visitors: 190 },
  { month: "Oct", visitors: 200 },
  { month: "Nov", visitors: 210 },
  { month: "Dec", visitors: 220 },
];

// Visitor registration by country
const dataByCountry = [
  { country: "India", visitors: 300 },
  { country: "USA", visitors: 150 },
  { country: "Germany", visitors: 100 },
  { country: "UK", visitors: 120 },
  { country: "China", visitors: 90 },
  { country: "Japan", visitors: 110 },
  { country: "Brazil", visitors: 80 },
  { country: "South Korea", visitors: 70 },
];

// Visitor registration by product category
const dataByCategory = [
  { category: "IMTEX", visitors: 200 },
  { category: "Digital Manufacturing", visitors: 150 },
  { category: "Automation", visitors: 120 },
  { category: "Robotics", visitors: 90 },
  { category: "3D Printing", visitors: 60 },
];

// Visitor registration by designation
const dataByDesignation = [
  { designation: "CEO", visitors: 50 },
  { designation: "Managing Director", visitors: 70 },
  { designation: "Manager", visitors: 120 },
  { designation: "Engineer", visitors: 100 },
  { designation: "Technician", visitors: 60 },
  { designation: "Vice President", visitors: 40 },
  { designation: "General Manager", visitors: 80 },
  { designation: "Executive", visitors: 90 },
  { designation: "Trainee", visitors: 30 },
  { designation: "Consultant", visitors: 20 },
];

const colors = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const AppBarHeader = ({ open, handleDrawerOpen }) => (
  <AppBar position="fixed">
    <Toolbar>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        sx={{ mr: 2, ...(open && { display: "none" }) }}
      >
        <MenuIcon />
      </IconButton>
      <Typography variant="h6" noWrap component="div">
        IMTEX 2025
      </Typography>
      <Box sx={{ flexGrow: 1 }} />
      <IconButton color="inherit">
        <NotificationsIcon />
      </IconButton>
      <IconButton color="inherit">
        <AccountCircle />
      </IconButton>
    </Toolbar>
  </AppBar>
);
const menuItems = [
  {
    text: "Dashboard",
    link: "/dashboard",
    icon: "DashboardIcon",
  },
  {
    text: "Visitor Registration",
    link: "#",
    icon: "ExploreIcon",
    subcategories: [
      {
        text: "Settings",
        link: "/explore/exhibitors",
        icon: "GroupIcon",
      },
      {
        text: "Preview",
        link: "/explore/products",
        icon: "CategoryIcon",
      },
      {
        text: "Terms & Conditions",
        link: "/explore/matchmaking",
        icon: "BusinessCenterIcon",
      },
      {
        text: "Badge Preview",
        link: "/explore/visitors",
        icon: "PeopleAltIcon",
      },
    ],
  },
  {
    text: "Floor Plan",
    link: "/floor-plan",
    icon: "MapIcon",
  },
  {
    text: "Exhibitors",
    link: "#",
    icon: "NaturePeopleIcon",
    subcategories: [
      {
        text: "Exhibitor List",
        link: "/explore/exhibitors",
        icon: "GroupIcon",
      },
      {
        text: "Add Exhibitor",
        link: "/explore/exhibitors",
        icon: "GroupIcon",
      },
    ],
  },
  {
    text: "Product Category",
    link: "/catalogue",
    icon: "BookIcon",
  },
  {
    text: "My Schedule",
    link: "/my-schedule",
    icon: "ScheduleIcon",
  },
  {
    text: "Favorites",
    link: "/favorites",
    icon: "FavoriteIcon",
  },
  {
    text: "Reports",
    link: "/reports",
    icon: "AssessmentIcon",
  },
  {
    text: "Settings",
    link: "/settings",
    icon: "SettingsIcon",
  },
  {
    text: "Logout",
    link: "/logout",
    icon: "LogoutIcon",
  },
];

function ResponsiveUI() {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  return (
    <ThemeProvider theme={ExpoTheme}>
      <Box sx={{ display: "flex" }}>
        <AppBarHeader open={open} handleDrawerOpen={handleDrawerOpen} />
        <SideMenuBar open={open} setOpen={setOpen} menuItems={menuItems} />
        <Box
          component="main"
          position={"fixed"}
          width={"100%"}
          height={"100vh"}
          ml={7}
          sx={{
            overflowY: "auto",
            p: 3,
            transition: ExpoTheme.transitions.create("margin", {
              easing: ExpoTheme.transitions.easing.sharp,
              duration: ExpoTheme.transitions.duration.leavingScreen,
            }),
          }}
        >
          <Toolbar />
          <Box mr={7}>
            <Grid container spacing={2}>
              <Grid xs={12} item md={9}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Card>
                      <CardContent
                        sx={{
                          backgroundImage: `url(${bgBanner})`,
                          backgroundSize: "cover",
                          height: 192,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          backgroundRepeat: "no-repeat",
                          color: "white",
                        }}
                      >
                        <Typography variant="h5" component="div">
                          Welcome!
                        </Typography>
                        <Typography fontSize={17} variant="body1">
                          ExpoPlanner Manage Admin
                        </Typography>
                        <Typography mt={2} variant="body2">
                          Manage Exhibitors, Visitors, Regisrations, OES, Floor
                          Plan
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Card>
                      <CardContent
                        sx={{
                          height: 192,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="h5" component="div">
                          Exhibitors
                        </Typography>
                        <Typography
                          variant="h2"
                          component="div"
                          color="primary"
                        >
                          0
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Card>
                      <CardContent
                        sx={{
                          height: 192,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="h5" component="div">
                          Visitors
                        </Typography>
                        <Typography
                          variant="h2"
                          component="div"
                          color="primary"
                        >
                          0
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  {/* <Grid item xs={12} md={3}>
                    <Card>
                      <CardContent sx={{ height: 96 }}>
                        <Typography>Card 1</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Card>
                      <CardContent sx={{ height: 96 }}>
                        <Typography>Card 1</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Card>
                      <CardContent sx={{ height: 96 }}>
                        <Typography>Card 1</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Card>
                      <CardContent sx={{ height: 96 }}>
                        <Typography>Card 1</Typography>
                      </CardContent>
                    </Card>
                  </Grid> */}
                </Grid>
              </Grid>
              <Grid item xs={12} md={3}>
                <Card sx={{ height: 192 }}>
                  <CardContent>
                    <Typography>Card 4</Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {/* Visitor Registrations by Year and Month */}
                  <Grid item xs={12} md={6}>
                    <Card>
                      <CardContent>
                        <Typography variant="h6">
                          Registrations by Month
                        </Typography>
                        <ResponsiveContainer width="100%" height={250}>
                          <BarChart data={dataByMonth}>
                            <XAxis dataKey="month" />
                            <YAxis
                              domain={[
                                0,
                                (dataMax) => Math.ceil(dataMax * 1.2),
                              ]} // Extend Y-axis by 20% to prevent clipping
                            />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="visitors" fill="#8884d8">
                              <LabelList dataKey="visitors" position="top" />
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                      </CardContent>
                    </Card>
                  </Grid>

                  {/* Visitor Registrations by Countries */}
                  <Grid item xs={12} md={6}>
                    <Card>
                      <CardContent>
                        <Typography variant="h6">
                          Registrations by Country
                        </Typography>
                        <ResponsiveContainer width="100%" height={250}>
                          <BarChart data={dataByCountry}>
                            <XAxis dataKey="country" />
                            <YAxis
                              domain={[
                                0,
                                (dataMax) => Math.ceil(dataMax * 1.2),
                              ]} // Extend Y-axis by 20% to prevent clipping
                            />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="visitors" fill="#82ca9d">
                              <LabelList dataKey="visitors" position="top" />
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                      </CardContent>
                    </Card>
                  </Grid>

                  {/* Visitor Registrations by Product Category */}
                  <Grid item xs={12} md={6}>
                    <Card>
                      <CardContent>
                        <Typography variant="h6">
                          Registrations by Product Category
                        </Typography>
                        <ResponsiveContainer width="100%" height={250}>
                          <PieChart>
                            <Pie
                              data={dataByCategory}
                              dataKey="visitors"
                              nameKey="category"
                              outerRadius={80}
                              fill="#8884d8"
                              label // This adds labels on the slices
                            >
                              {dataByCategory.map((entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={colors[index % colors.length]}
                                />
                              ))}
                            </Pie>
                            <Tooltip />
                            <Legend />{" "}
                          </PieChart>
                        </ResponsiveContainer>
                      </CardContent>
                    </Card>
                  </Grid>

                  {/* Visitor Registrations by Designations */}
                  <Grid item xs={12} md={6}>
                    <Card>
                      <CardContent>
                        <Typography variant="h6">
                          Registrations by Designations
                        </Typography>
                        <ResponsiveContainer width="100%" height={250}>
                          <BarChart data={dataByDesignation}>
                            <XAxis dataKey="designation" />
                            <YAxis
                              domain={[
                                0,
                                (dataMax) => Math.ceil(dataMax * 1.2),
                              ]} // Extend Y-axis by 20% to prevent clipping
                            />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="visitors" fill="#ff7300">
                              <LabelList dataKey="visitors" position="top" />
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default ResponsiveUI;
