import React, { useState } from "react";
import ExhibitorCard from "../../../../widgets/ExhibitorCard";
import {
  Box,
  Grid,
  Pagination,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SearchBar from "../../../../widgets/searchBar";
import { API_ENDPOINT } from "../../../../constants/constants";
import CustomCircularP from "../../../../widgets/CustomCircularP";
import { LoadingButton } from "@mui/lab";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterDialogMui from "../../../../widgets/FilterDialogMui";
import useApiEndpoint from "../../../events/hooks/useApiEndpoint";
import useCommonGetInit from "../../../events/hooks/useCommonGetInit";
import AlphabetSortWidget from "./AlphabetSortWidget";
import ExhibitorFilterBar from "./ExhibitorFilterBar";

const Exhibitors = () => {
  const [page, setPage] = useState(1);
  const [sq, setSq] = useState("");
  const [filters, setFilters] = useState({
    search: "",
    hall: "",
    country: "",
    cat: "",
  });
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const [selectedLetter, setSelectedLetter] = useState("");
  const { data, isLoading, isError, error } = useCommonGetInit(
    `user/get-exhibitors?page=${page}&sq=${filters.search}&hall=${filters.hall}&country=${filters.country}&letter=${selectedLetter}&cat=${filters.cat}`
  );
  const onPageChange = (e, page) => {
    setPage(page);
  };
  const handleSearch = (searchTerm) => {
    setFilters((prev) => ({ ...prev, search: searchTerm }));
  };

  const handleFilter = ({ hall, country, cat }) => {
    setFilters((prev) => ({
      ...prev,
      hall: hall !== undefined ? hall : prev.hall,
      country: country !== undefined ? country : prev.country,
      cat: cat !== undefined ? cat : prev.cat,
    }));
    // Implement your filter logic here
  };
  return (
    <div>
      <CustomCircularP show={isLoading} />
      <div>
        <ExhibitorFilterBar onSearch={handleSearch} onFilter={handleFilter} />
        {isLargeScreen && (
          <AlphabetSortWidget
            onSelectLetter={(newV) => {
              if (newV) {
                setSelectedLetter(newV);
              } else {
                setSelectedLetter("");
              }
            }}
          />
        )}
        <Grid container spacing={3}>
          {data?.data?.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <ExhibitorCard key={index} item={item} />
            </Grid>
          ))}
        </Grid>
        <Box mt={2} mb={2} justifyContent={"center"} display={"flex"}>
          <Pagination
            count={Math.ceil(data?.totalItems / 30)}
            page={page}
            onChange={onPageChange}
            color="primary"
          />
        </Box>
      </div>
    </div>
  );
};

export default Exhibitors;
