import React from "react";
import useApiEndpoint from "../../events/hooks/useApiEndpoint";
import { API_ENDPOINT } from "../../../constants/constants";
import MuPb from "../../../widgets/MuPb";
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import BackBtn from "../admin/widgets/BackBtn";
import { getUser } from "../../../utility/utility";
import useCommonGetInit from "../../events/hooks/useCommonGetInit";

const MyChatList = ({ internal = false }) => {
  const navigate = useNavigate();
  const { data, isLoading, isError, error } =
    useCommonGetInit(`user/get-my-chats`);

  const handleItemClick = (chat) => {
    navigate(`/${getUser()?.role}/chat?tid=${chat._id}`, { replace: true });
  };

  if (isLoading) {
    return <MuPb />;
  }

  if (isError) {
    return <Typography color="error">{error.message}</Typography>;
  }

  return (
    <Box
      ml={"auto"}
      mr={"auto"}
      pl={2}
      pr={2}
      mt={2}
      pt={2}
      maxWidth={720}
      bgcolor={"white"}
    >
      {!internal && (
        <>
          <BackBtn />{" "}
          <Typography variant="h6" gutterBottom>
            My Chats
          </Typography>
        </>
      )}
      <List>
        {data.map((chat, index) => (
          <React.Fragment key={chat._id}>
            <Box
              onClick={() => handleItemClick(chat)}
              sx={{ cursor: "pointer" }}
            >
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar
                    alt={
                      chat.firstName
                        ? `${chat.firstName} ${chat.lastName}`
                        : chat.companyName.value
                    }
                    src={chat.profile_image}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    chat.firstName
                      ? `${chat.firstName} ${chat.lastName}`
                      : chat.companyName.value
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        color="textPrimary"
                      >
                        {chat.latestMessage}
                      </Typography>
                      {/* {" — "}
                      {chat.companyName.label || chat.companyName} */}
                    </React.Fragment>
                  }
                />
              </ListItem>
            </Box>
            {index < data.length - 1 && (
              <Divider variant="inset" component="li" />
            )}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default MyChatList;
